import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = props => {

  let activeColor           = '#2D8D95';
  let passiveColor          = '#343333';

  let section1style         = { color: activeColor, fontWeight: '700' };
  let section2style         = { color: passiveColor, fontWeight: '400' };
  let section3style         = { color: passiveColor, fontWeight: '400' };
  let section4style         = { color: passiveColor, fontWeight: '400' };

  const [section1, setSection1] = React.useState({ color: activeColor, fontWeight: '700' });
  const [section2, setSection2] = React.useState({ color: passiveColor, fontWeight: '400' });
  const [section3, setSection3] = React.useState({ color: passiveColor, fontWeight: '400' });
  const [section4, setSection4] = React.useState({ color: passiveColor, fontWeight: '400' });
  
  let progressWidth         = '25%';
  if(window.innerWidth < 650) {
    progressWidth           = '50%';
  }

  let progress1             = 0;
  let progress2             = 0;
  let progress3             = 0;
  let progress4             = 0;

  if(props.currentProgress < 4) {
    progress1               = ((props.currentProgress + 1) / 4) * 100;
    section1style           = { color: activeColor, fontWeight: '700' };
    section2style           = { color: passiveColor, fontWeight: '400' };
    section3style           = { color: passiveColor, fontWeight: '400' };
    section4style           = { color: passiveColor, fontWeight: '400' };
  } else {
    progress1               = 100;
    section1style           = { color: passiveColor, fontWeight: '400' };
  }

  if(props.currentProgress >= 4 && props.currentProgress < 10) {
    progress2               = (((props.currentProgress + 1) - 4) / 6) * 100;
    section1style           = { color: passiveColor, fontWeight: '400' };
    section2style           = { color: activeColor, fontWeight: '700' };
    section3style           = { color: passiveColor, fontWeight: '400' };
    section4style           = { color: passiveColor, fontWeight: '400' };
  } else {
    if(props.currentProgress < 9) {
      progress2             = 0;
    } else {
      progress2             = 100;
    }
    section2style           = { color: passiveColor };
  }

  if(props.currentProgress >= 10 && props.currentProgress < 15) {
    progress3               = (((props.currentProgress + 1) - 10) / 5) * 100;
    section1style           = { color: passiveColor, fontWeight: '400' };
    section2style           = { color: passiveColor, fontWeight: '400' };
    section3style           = { color: activeColor, fontWeight: '700' };
    section4style           = { color: passiveColor, fontWeight: '400' };
  } else {
    if(props.currentProgress < 14) {
      progress3             = 0;
    } else {
      progress3             = 100;
    }
    section3style           = { color: passiveColor, fontWeight: '400' };
  }

  if(props.currentProgress >= 15 && props.currentProgress < 19) {
    progress4               = (((props.currentProgress + 1) - 15) / 4) * 100;
    section1style           = { color: passiveColor, fontWeight: '400' };
    section2style           = { color: passiveColor, fontWeight: '400' };
    section3style           = { color: passiveColor, fontWeight: '400' };
    section4style           = { color: activeColor, fontWeight: '700' };
  } else {
    if(props.currentProgress < 18) {
      progress4             = 0;
    } else {
      progress4             = 100;
    }
    section4style           = { color: passiveColor };
  }

  let progressPercentage = Math.round(((props?.currentProgress + 1) / 19) * 100);

  return (
    <div>
      <p className="progress-percent-text" variant="determinate" style={{
        marginLeft: `calc(${progressPercentage}% - 2ch)`
      }}>{`${progressPercentage}%`}</p>
      <div className="progress-bar">                
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress1} /></div>
          <div className="section-title" style={section1style}>STRATEGY</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress2} /></div>
          <div className="section-title" style={section2style}>PARTNERSHIP</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress3} /></div>
          <div className="section-title" style={section3style}>ENGAGEMENT</div>
        </div>
        <div className="bar-section" style={{width: progressWidth}}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress4} /></div>
          <div className="section-title" style={section4style}>MISSION & VISION</div>
        </div>
      </div>

    </div>
  );
}
export default ProgressBar;
